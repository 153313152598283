import { render, staticRenderFns } from "./newsletter-mobile-page.vue?vue&type=template&id=048c5719&scoped=true&"
import script from "./newsletter-mobile-page.vue?vue&type=script&lang=js&"
export * from "./newsletter-mobile-page.vue?vue&type=script&lang=js&"
import style0 from "./newsletter-mobile-page.vue?vue&type=style&index=0&id=048c5719&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048c5719",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KGdpr from '@web/components/core/k-gdpr'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import KProductTitle from '@web/components/core/k-product-title'
import KSelect from '@web/components/core/k-select'
installComponents(component, {KButton,KGdpr,KInput,KLayout,KProductTitle,KSelect})
