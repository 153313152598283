<template>
    <k-layout>
        <div class="tw-relative tw-bg-white tw-min-h-screen">
            <!-- Side Panel -->
            <aside ref="aside" class="tw-hidden lg:tw-block tw-bg-black tw-h-full tw-w-full tw-absolute tw-top-0 tw-left-0 tw-z-0">
                <img
                    src="./assets/newsletter-mobile.svg"
                    class="tw-absolute"
                    :style="{ height: '80%', bottom: '0rem', right: '-4rem' }"
                    :draggable="false"
                    alt=""
                />
            </aside>

            <div class="tw-flex tw-flex-col tw-min-h-screen">
                <nav class="tw-fixed tw-top-0 tw-w-full tw-bg-white md:tw-bg-transparent tw-z-10 tw-shadow md:tw-shadow-none">
                    <div class="tw-w-full tw-mx-auto tw-px-4 lg:tw-px-8">
                        <div class="tw-flex tw-items-center tw-justify-between tw-h-16 lg:tw-h-20">
                            <div class="tw-flex-shrink-0">
                                <router-link to="/">
                                    <ka-logo light icon class="tw-block tw-w-auto tw-h-8 lg:tw-hidden" />
                                    <ka-logo light class="tw-hidden tw-w-auto tw-h-8 lg:tw-block" />
                                </router-link>
                            </div>
                            <div>
                                <router-link
                                    to="/"
                                    class="hover:tw-text-koderia tw-text-black tw-transition-colors tw-duration-75 tw-ease-in tw-no-underline"
                                >
                                    {{ $t('global.actions.back_to_home_page') }} ›
                                </router-link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div class="tw-items-center tw-justify-center tw-w-full tw-flex tw-flex-1 tw-p-4">
                    <div class="tw-w-full tw-max-w-md tw-py-16">
                        <k-product-title title="Newsletter" />
                        <h2 class="tw-mt-6 tw-heading-2">{{ $t('newsletter.subtitles.android_ios_projects') }}</h2>
                        <p class="tw-mt-6">
                            {{ $t('newsletter.descriptions.send_personalized_projects') }} <strong>{{ $t('newsletter.descriptions.no_spam') }}.</strong>
                        </p>
                        <form @submit.prevent="submit" ref="form" class="tw-mt-10">
                            <div class="tw-grid tw-gap-4 tw-grid-cols-2">
                                <k-input v-model="form.email" :invalid="submitted && !isValidField('email')" placeholder="E-mail" class="tw-col-span-2" />
                                <k-select
                                    v-model="form.profession"
                                    :invalid="submitted && !isValidField('profession')"
                                    :items="allProfessions"
                                    :placeholder="$t('global.inputs.profession')"
                                    class="tw-col-span-2"
                                    item-value="value"
                                    item-text="name"
                                />
                                <k-input
                                    v-model="form.firstName"
                                    :invalid="submitted && !isValidField('firstName')"
                                    :placeholder="$t('global.inputs.first_name')"
                                    class="tw-col-span-2 sm:tw-col-span-1"
                                />
                                <k-input
                                    v-model="form.lastName"
                                    :invalid="submitted && !isValidField('lastName')"
                                    :placeholder="$t('global.inputs.last_name')"
                                    class="tw-col-span-2 sm:tw-col-span-1"
                                />
                            </div>

                            <k-gdpr
                                v-model="form.gdpr"
                                :showGdrp="false"
                                :invalidNewsletter="submitted && !isValidField('gdpr')"
                                newsletterLabelText="gdpr.descriptions.newsletter_personalized_offers"
                                :newsletterTooltip="$t('gdpr.descriptions.newsletter_contact_me_tooltip')"
                                class="tw-mt-7"
                            />

                            <k-button type="submit" color="primary" class="tw-w-full tw-mt-6" :success="success">
                                {{ $t('auth.actions.sign_in_simple') }}
                            </k-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </k-layout>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import { mapGetters } from "vuex";

import KaLogo from "@web/components/ka-logo";

export default {
    components: { KaLogo },
    data() {
        return {
            submitted: false,
            success: false,
            form: {
                email: undefined,
                firstName: undefined,
                lastName: undefined,
                profession: undefined,
                gdpr: undefined
            }
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize, false);
            this.$on("hook:beforeDestroy", () => {
                window.removeEventListener("resize", this.onResize, false);
            });
            this.onResize();
        });
    },
    computed: {
        user() {
            return this.$store.state.AUTH.user;
        },
        ...mapGetters("ENUMS", {
            allProfessions: "allMobileStacksNames"
        })
    },
    watch: {
        user: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.$router.push({ name: "profile", query: { tab: "emailove-nastavenia" } });
                }
            }
        },
        allProfessions: {
            immediate: true,
            handler(value) {
                const [first] = value || [];
                this.form.profession = first;
            }
        }
    },
    methods: {
        async submit() {
            this.submitted = true;

            const fields = ["email", "firstName", "lastName", "profession", "gdpr"];
            this.isFormValid = fields.map(field => !!this.isValidField(field)).every(Boolean);

            if (this.isFormValid) {
                const { email, firstName, lastName, profession } = this.form;
                firebase.functions().httpsCallable("subscribeMailchimpUser")({
                    email: email,
                    name: `${firstName} ${lastName}`,
                    profession
                });
                this.success = true;
                window.location.hash = "#newsletter-hotovo";
                setTimeout(() => {
                    this.$router.push({ name: "Home", hash: "#newsletter-hotovo" });
                }, 2500);
            }
        },
        isValidField(fieldName) {
            const { [fieldName]: value } = this.form || {};

            switch (fieldName) {
                case "firstName":
                case "lastName":
                case "profession":
                    return typeof value === "string" && String(value).trim().length > 0;
                case "email":
                    return typeof value === "string" && /(.+)@(.+){2,}\.(.+){2,}/.test(value);
                case "gdpr":
                    return value && typeof value === "object" && value.newsletterValue === true;
                default:
                    return false;
            }
        },
        onResize() {
            const { aside, form } = this.$refs;
            if (aside && form) {
                if (form.offsetLeft - 240 <= 225) {
                    aside.style.width = "14rem";
                } else {
                    aside.style.width = `${((form.offsetLeft || 0) - 240) / 16}rem`;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.character {
    width: 27rem;
    position: absolute;
    bottom: 0;
    right: -4rem;
}
</style>
